import React, { useContext } from 'react';

import DocumentsContext from '@totem/components/documents/report/documentsContext';
import DocumentsTable from '@totem/components/documents/report/documentsTable';
import DocumentUploadButton from '@totem/components/documents/report/documentUploadButton';
import PageSection from '@totem/components/PageSection';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import ComponentHeader from '@totem/components/ComponentHeader';

const DocumentsPanel = () => {
  const { totalRecords } = useContext(DocumentsContext);

  const styles = {
    inset: {
      marginLeft: '20px',
      marginRight: '20px',
    },
  };

  return (
    <PageSection>
      <div style={styles.inset}>
        <ComponentHeader
          title={'Documents'}
          options={
              <>
                <RecordCountBadge count={totalRecords} />
                &nbsp;&nbsp;&nbsp;
                <DocumentUploadButton />
              </>
          }
        />
        <br />
        <DocumentsTable />
      </div>
    </PageSection>
  );
};

export default DocumentsPanel;
