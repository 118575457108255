import { createContext } from 'react';
import { OwnerItem, SupportLine } from '@totem/components/supportLines/types';

interface Context {
  loading: boolean;
  owner: OwnerItem;
  data: OwnerItem;
  action: string;
  actionItem: SupportLine;
  onAction?: (action: string, item: SupportLine) => void;
}

export default createContext<Context>({
  loading: false,
  owner: null,
  data: null,
  action: '',
  actionItem: null,
  onAction: () => {},
});
