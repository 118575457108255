import React, { useContext } from 'react';
import SupportLinesContext from '@totem/components/supportLines/supportLinesContext';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import { isNotNull } from '@totem/utilities/common';

const SupportLinesRecordCount = () => {
  const { data } = useContext(SupportLinesContext)
  return <RecordCountBadge count={ isNotNull(data) && isNotNull(data.supportLines) ? data.supportLines.length : 0 } />
}

export default SupportLinesRecordCount;
