import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, DatePicker, Checkbox, Row, Col, notification, Tabs } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { OwnerItem, SupportLine } from '@totem/components/supportLines/types';
import { isNotNull } from '@totem/utilities/common';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import UserProfileContext from '@totem/components/UserProfileContext';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import TabTitle from '@totem/components/TabTitle';
const { RangePicker } = DatePicker;

interface Props {
  visible: boolean;
  onClose: (refresh: boolean) => void;
  owner: OwnerItem;
  supportLine?: SupportLine;
}

const SupportLineEditDialog = ({
     visible,
     onClose,
     owner,
     supportLine,
   }: Props) => {
  console.log('Param' + JSON.stringify(supportLine));
  const { userProfile } = useContext(UserProfileContext);
  const currentTime = dayjs();
  const [isSending, setIsSending] = useState(false);
  const [line, setLine] = useState<SupportLine>(isNotNull(supportLine) ? supportLine : {
    id: EMPTY_ID,
    startDate: currentTime.toISOString(),
    endDate: currentTime.toISOString(),
    autoRenewable: false,
    companyName: '',
    contractContact: {
      name: '',
      phone: '',
      email: '',
    },
    supportContact: {
      name: '',
      phone: '',
      email: '',
    },
    serviceLevelAgreement: '',
    createdBy: userProfile.email,
    createdAt: currentTime.toISOString(),
    updatedBy: userProfile.email,
    updatedAt: currentTime.toISOString(),
  });

  console.log('Line: ' + JSON.stringify(supportLine));

  useEffect(() => {
    if (isNotNull(supportLine)) {
      setLine(supportLine);
    }
  }, [supportLine]);

  const sendAddUpdateSupportLine = useCallback(
    async (paramOwner: OwnerItem, paramLine: SupportLine) => {
      const payload = {
        ownerItem: paramOwner,
        supportLine: paramLine
      }

      fetch(
        `${DEVICES_ENDPOINT}/supportLines`,
        {
          method: 'PUT',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(payload),
        },
      ).then((res) => {
        setIsSending(false);
        const success = CheckResponseShowError(res);
        if (success) {
          onClose(true)
        }
      });
    },
    [],
  );

  const isValid = () => {
    let isValid = true;
    if (line.startDate > line.endDate) {
      isValid = false;
      notification.error({
        message: 'Invalid Date/Time Entry!',
        description:
          'End time must be after start time!',
        duration: 3,
      });
    }
    if (line.companyName === '') {
      isValid = false;
      notification.error({
        message: 'Invalid Company Name!',
        description:
          'Company Name Required!',
        duration: 3,
      });
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (isValid()) {
      sendAddUpdateSupportLine(owner, line);
    }
  };

  return (
    <Modal
      title={supportLine ? 'Update Support Line' : 'Create Support Line'}
      open={visible}
      onCancel={() => onClose(false)}
      onOk={ isSending ? null : handleSubmit}
      okText={supportLine ? 'Update' : 'Create'}
    >
      <Form layout="vertical">
        <Form.Item
          label="Company Name"
          required
        >
          <Input
            name="companyName"
            type="text"
            value={line.companyName}
            onChange={(evt) => setLine((prevState) => ({ ...prevState, companyName: evt.target.value }))}
          />
        </Form.Item>

        <Form.Item
          label="Contract Duration"
          required
        >
          <RangePicker value={[dayjs(line.startDate), dayjs(line.endDate)]} onChange={(dates: null | (Dayjs | null)[]) => setLine((prevState) => ({ ...prevState, startDate: dates[0].toISOString(), endDate: dates[1].toISOString() }))} />
        </Form.Item>

        <Form.Item>
          <Checkbox checked={line.autoRenewable} onChange={(evt: CheckboxChangeEvent) => setLine((prevState) => ({ ...prevState, autoRenewable: evt.target.checked }))}>Auto Renewable</Checkbox>
        </Form.Item>

        <Form.Item
          label="Service Level Agreement"
        >
          <Input value={line.serviceLevelAgreement} onChange={(evt) => setLine((prevState) => ({ ...prevState, serviceLevelAgreement: evt.target.value }))} />
        </Form.Item>

        <Tabs items={[
          {
            key: 'contract',
            label: <TabTitle>Contract Contact</TabTitle>,
            children: (
              <>
                <Form.Item
                  label="Name"
                >
                  <Input value={line.contractContact.name} onChange={(evt) => setLine((prevState) => ({ ...prevState, contractContact: { ...prevState.contractContact, name: evt.target.value }}))} />
                </Form.Item>
                <Form.Item
                  label="Phone"
                >
                  <Input value={line.contractContact.phone} onChange={(evt) => setLine((prevState) => ({ ...prevState, contractContact: { ...prevState.contractContact, phone: evt.target.value }}))} />
                </Form.Item>
                <Form.Item
                  label="Email"
                >
                  <Input value={line.contractContact.email} onChange={(evt) => setLine((prevState) => ({ ...prevState, contractContact: { ...prevState.contractContact, email: evt.target.value }}))} />
                </Form.Item>
              </>
            ),
          },
          {
            key: 'support',
            label: <TabTitle>Support Contact</TabTitle>,
            children: (
              <>
                <Form.Item
                  label="Name"
                >
                  <Input value={line.supportContact.name} onChange={(evt) => setLine((prevState) => ({ ...prevState, supportContact: { ...prevState.supportContact, name: evt.target.value }}))} />
                </Form.Item>
                <Form.Item
                  label="Phone"
                >
                  <Input value={line.supportContact.phone} onChange={(evt) => setLine((prevState) => ({ ...prevState, supportContact: { ...prevState.supportContact, phone: evt.target.value }}))} />
                </Form.Item>
                <Form.Item
                  label="Email"
                >
                  <Input value={line.supportContact.email} onChange={(evt) => setLine((prevState) => ({ ...prevState, supportContact: { ...prevState.supportContact, email: evt.target.value }}))} />
                </Form.Item>
              </>
            ),
          }
        ]} />
      </Form>
    </Modal>
  );
};

export default SupportLineEditDialog;
