import React, { useContext, useState } from 'react';
import { Empty, Tabs, TabsProps } from 'antd';

import DeviceTable from '@totem/components/buildingDetail/DeviceTable';
import RemoteAccessRoles from '@totem/components/common/remoteAccess/panel/RemoteAccessRoles';
import ComponentHeader from '@totem/components/ComponentHeader';
import ContentLayout from '@totem/components/ContentLayout';
import ComplianceSelector from '@totem/components/controlSystemDetail/ComplianceSelector';
import ControlSystemContext from '@totem/components/controlSystemDetail/container/ControlSystemContext';
import ControlSystemSidebar from '@totem/components/controlSystemDetail/controlSystemSidebar/ControlSystemSidebar';
import DeviceBackupPanel from '@totem/components/deviceBackups/DeviceBackupPanel';
import DeviceContainer from '@totem/components/devices/devicesContainer/DeviceContainer';
import DocumentsContainer from '@totem/components/documents/report/documentsContainer';
import DocumentsPanel from '@totem/components/documents/report/documentsPanel';
import EventFilterContainer from '@totem/components/events/eventFilters/EventFilterContainer';
import EventContainerTable from '@totem/components/events/eventsContainer/EventContainerTable';
import EventEmbeddedContainer from '@totem/components/events/eventsContainer/EventEmbeddedContainer';
import PageSection from '@totem/components/PageSection';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import ComplianceSummaryContainer from '@totem/components/surveyV2/complianceSummary/complianceSummaryContainer';
import PolicyAuditReportContainer from '@totem/components/surveyV2/policyAuditReport/policyAuditReportContainer';
import ReportTable from '@totem/components/surveyV2/policyAuditReport/ReportTable';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { isNotNull, isNull } from '@totem/utilities/common';
import {
  emptyId,
  hasAnyActiveService,
  isMemberOfAny,
} from '@totem/utilities/userUtilities';

import './controlSystemDetail.css';
import SupportLinesContainer from '@totem/components/supportLines/supportLinesContainer';
import SupportLinesTable from '@totem/components/supportLines/supportLinesTable';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import DocumentUploadButton from '@totem/components/documents/report/documentUploadButton';
import SupportLinesRecordCount from '@totem/components/supportLines/supportLinesRecordCount';
import SupportLineAddButton from '@totem/components/supportLines/supportLineAddButton';

const styles = {
  inset: {
    marginLeft: '20px',
    marginRight: '20px',
  },
};

const ControlSystemDetailContent = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { data } = useContext(ControlSystemContext);
  const [deviceTotal, setDeviceTotal] = useState<number>(0);
  const [eventTotal, setEventTotal] = useState<number>(0);

  const id = isNotNull(data) ? data.controlSystem.id : emptyId;

  const { remoteAccess: showRemoteAccess } =
    userProfile.organization?.preferences?.features;

  const canSeeAudits = userProfile.organization.role >= 3;

  const userCanSeeRemoteAccess = isNotNull(data)
    ? isMemberOfAny(
        userProfile,
        [
          'remote_access_viewer',
          'remote_access_admin',
          'remote_access_configuration_admin',
        ],
        {
          regionId: data.region.id,
          buildingId: data.building.id,
          controlSystemId: data.controlSystem.id,
        },
      )
    : false;

  const userCanSeeEvents = hasAnyActiveService(userProfile, [
    'threat_management',
  ]);

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: '1',
        label: <TabTitle>Compliance</TabTitle>,
        children: (
          <PageSection>
            <ComplianceSummaryContainer controlSystemId={[id]}>
              <ComplianceSelector />
            </ComplianceSummaryContainer>
          </PageSection>
        ),
      },
      {
        key: '4',
        label: <TabTitle>Continuity</TabTitle>,
        children: (
          <PageSection>
            <DeviceBackupPanel
              pRegionId={[]}
              pBuildingId={[]}
              pControlSystemId={[id]}
              pDeviceId={[]}
            />
          </PageSection>
        ),
      },
      {
        key: 'devices',
        label: <TabTitle>Devices</TabTitle>,
        children: (
          <PageSection>
            <DeviceContainer
              staticFilters={{
                controlSystemId: [id],
              }}
              onRecordTotalChanged={(total) => setDeviceTotal(total)}
            >
              <ComponentHeader
                title={'Devices'}
                options={<RecordCountBadge count={deviceTotal} />}
              />
              <DeviceTable />
            </DeviceContainer>
          </PageSection>
        ),
      },
    ];

    if (canSeeAudits) {
      tabItems.push({
        key: 'audits',
        label: <TabTitle>Audits</TabTitle>,
        children: (
          <PageSection>
            <PolicyAuditReportContainer
              staticFilters={{ controlSystemId: [id] }}
            >
              <ReportTable />
            </PolicyAuditReportContainer>
          </PageSection>
        ),
      });
    }

    if (userCanSeeEvents) {
      tabItems.push({
        key: '2',
        label: <TabTitle>Events</TabTitle>,
        children: (
          <PageSection>
            <EventFilterContainer
              staticFilters={{
                controlSystemId: [id],
                status: ['CREATED', 'ACKNOWLEDGED'],
              }}
            >
              <EventEmbeddedContainer
                onRecordTotalChanged={(total) => setEventTotal(total)}
              >
                <ComponentHeader
                  title={'Events'}
                  options={<RecordCountBadge count={eventTotal} />}
                />
                <EventContainerTable />
              </EventEmbeddedContainer>
            </EventFilterContainer>
          </PageSection>
        ),
      });
    }

    if (showRemoteAccess && userCanSeeRemoteAccess && isNotNull(data)) {
      tabItems.push({
        key: '3',
        label: <TabTitle>Remote Access</TabTitle>,
        children: (
          <PageSection>
            <RemoteAccessRoles
              regionId={data.region.id}
              buildingId={data.building.id}
              buildingName={data.building.name}
              controlSystemId={data.controlSystem.id}
              controlSystemName={data.controlSystem.name}
            />
          </PageSection>
        ),
      });
    }

    tabItems.push({
      key: 'documents',
      label: <TabTitle>Documents</TabTitle>,
      children: (
        <DocumentsContainer
          documentReference={{
            controlSystemId: data.controlSystem.id,
            regionId: data.controlSystem .regionId,
            buildingId: data.controlSystem.buildingId,
          }}
        >
          <div>
            <DocumentsPanel />
          </div>
        </DocumentsContainer>
      ),
    });

    tabItems.push({
      key: 'supportLines',
      label: <TabTitle>Third Party Support</TabTitle>,
      children: (
        <SupportLinesContainer
          owner={{
            id: EMPTY_ID,
            organizationId: userProfile.organization.id,
            regionId: data.controlSystem.regionId,
            buildingId: data.controlSystem.buildingId,
            controlSystemId: data.controlSystem.id,
            objectType: 'controlSystem',
            objectId: data.controlSystem.id,
            objectName: data.controlSystem.name,
            supportLines: [],
          }}
        >
          <PageSection>
            <div style={styles.inset}>
              <ComponentHeader
                title={'Third Party Support'}
                options={
                  <>
                    <SupportLinesRecordCount />
                    &nbsp;&nbsp;&nbsp;
                    <SupportLineAddButton />
                  </>
                }
              />
              <br />
              <SupportLinesTable />
            </div>
          </PageSection>
        </SupportLinesContainer>
      ),
    });

    return tabItems;
  };

  return (
    <>
      {isNull(data) && <Empty />}
      {isNotNull(data) && (
        <ContentLayout
          pageTitle={isNotNull(data) ? data.controlSystem.name : ''}
          contentStyle={{ padding: 0 }}
          pageSubtitle={[
            { label: userProfile.organization.name },
            { label: isNotNull(data) ? data.region.name : '' },
            {
              label: isNotNull(data) ? data.building.name : '',
              link: `/dashboard/buildings/${isNotNull(data) ? data.building.id : emptyId}`,
            },
          ]}
          breadcrumbs={(breadcrumbs) => [
            ...breadcrumbs,
            {
              label: isNotNull(data) ? data.building.name : '',
              link: `/dashboard/buildings/${isNotNull(data) ? data.building.id : emptyId}`,
            },
            {
              label: 'Control Systems',
              link: '/dashboard/controlSystemReport',
            },
            {
              label: 'System Details',
            },
          ]}
        >
          <div styleName="content">
            <div styleName="base-pane">
              <ControlSystemSidebar />
              <div styleName="main">
                <Tabs defaultActiveKey="1" items={getTabItems()} />
              </div>
            </div>
          </div>
        </ContentLayout>
      )}
    </>
  );
};

export default ControlSystemDetailContent;
