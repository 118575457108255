import React, { useContext, useState } from 'react';
import SupportLinesContext from '@totem/components/supportLines/supportLinesContext';
import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import SupportLineEditDialog from '@totem/components/supportLines/supportLineEditDialog';

const SupportLineAddButton = () => {
  const { owner, onAction } = useContext(SupportLinesContext);
  const [showDialog, setShowDialog] = useState(false);

  const handleClose = (refresh: boolean) => {
    onAction('refresh', null);
    setShowDialog(false);
  }

  return (
    <>
      <Button icon={<PlusCircleOutlined />} onClick={showDialog ? null : () => setShowDialog(true)}>Create Support Entry</Button>
      { showDialog && (
        <SupportLineEditDialog owner={owner} onClose={handleClose} visible={showDialog} />
      )}
    </>
  );
};

export default SupportLineAddButton;
