import { OwnerItem, SupportLine } from '@totem/components/supportLines/types';
import React, { ReactNode, useEffect, useState } from 'react';
import SupportLinesContext from '@totem/components/supportLines/supportLinesContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { isNotNull } from '@totem/utilities/common';

type Props = {
  children?: ReactNode;
  owner: OwnerItem;
}

const SupportLinesContainer = ({ owner, children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [data, setData] = useState<OwnerItem>(owner);
  const [action, setAction] = useState<string>('');
  const [actionItem, setActionItem] = useState<SupportLine>(null);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${DEVICES_ENDPOINT}/supportLines/${owner.objectId}`;

      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: OwnerItem[]) => {
          if (isNotNull(result)) {
            if (result.length > 0) {
              setData(result[0]);
            }
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [owner, refreshData]);

  const handleAction = (newAction: string, newActionItem: SupportLine) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        setActionItem(null);
        break;
      default:
        setAction(newAction);
        setActionItem(newActionItem);
        break;
    }
  };

  return (
    <SupportLinesContext.Provider value={{
      loading: isLoading,
      data,
      action,
      actionItem,
      owner,
      onAction: handleAction,
    }}>
      {children}
    </SupportLinesContext.Provider>
  );
};

export default SupportLinesContainer;
